import { ApiLoadingStatus } from "enum/api-loading-status.enum";
//import { GenericErrorCodes } from "enum/error-codes/generic-error-codes.enum";
import { PackageErrorCodes } from "enum/error-codes/package-error-codes.enum";
import { ShipmentDutyOrTaxPaidBy } from "enum/shipment-duty-or-tax-paid-by.enum";
import { ShipmentExportReasons } from "enum/shipment-export-reasons.enum";
import {
  ShipmentContentsFieldNames,
  ShipmentDetailFieldNames,
} from "enum/shipment-field-names.enum";
import { ShipmentTypes } from "enum/shipment-types.enum";
import { IAddress } from "models/address/address.model";
import { IQuotedRate } from "models/quote/quote.model";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import {
  CarrierDetailList,
  IShipment,
  IShipmentItemDescription,
  LocationDetail,
} from "models/shipment/shipment.model";
import { IShipmentPackageGrouped } from "models/shipmentPackage/shipmentPackage.model";

export enum CreateShipmentAddressType {
  "ADDRESS_BOOK" = "ADDRESS_BOOK",
  "NEW" = "NEW",
}

export type AddressOrigin = "sender" | "recipient";

export type CreateAddress = Pick<
  IAddress,
  | "addressLine1"
  | "addressLine2"
  | "addressLine3"
  | "city"
  | "zip"
  | "company"
  | "countyState"
  | "countryIsoCode"
  | "email"
  | "phone"
  | "firstName"
  | "lastName"
  | "eoriNumber"
  | "vatNumber"
  | "isSenderDefault"
>;

export type CreateShipmentAddress = CreateAddress & {
  id: IAddress["id"];
  saveToAddressBook: boolean;
  editModeSavedAddress?: boolean;
  type: CreateShipmentAddressType;
};

export type CreateShipmentContents = Pick<
  IShipmentItemDescription,
  | ShipmentContentsFieldNames.commodityCode
  | ShipmentContentsFieldNames.countryOfOrigin
  | ShipmentContentsFieldNames.currency
  | ShipmentContentsFieldNames.description
  | ShipmentContentsFieldNames.itemValue
  | ShipmentContentsFieldNames.manufacturerDetails
  | ShipmentContentsFieldNames.sku
  | ShipmentContentsFieldNames.weight
  | ShipmentContentsFieldNames.quantity
> & { packageId: number };

export type CreateShipmentDetails = {
  [ShipmentDetailFieldNames.customerReference]: string;
  [ShipmentDetailFieldNames.customerReference2]: string;
  [ShipmentDetailFieldNames.reasonForExport]: ShipmentExportReasons | null;
  [ShipmentDetailFieldNames.dutyOrTaxPaidBy]: ShipmentDutyOrTaxPaidBy;
  [ShipmentDetailFieldNames.shouldSplitDutyAndVat]: boolean;
  [ShipmentDetailFieldNames.invoiceNumber]: string;
  [ShipmentDetailFieldNames.iossNumber]: string;
  [ShipmentDetailFieldNames.dangerousGoods]: string;
  [ShipmentDetailFieldNames.shipmentSourceName]: string;
  [ShipmentDetailFieldNames.integrationStoreID]: number;
  [ShipmentDetailFieldNames.shipmentIntegrationID]?: number;
  [ShipmentDetailFieldNames.orderNumber]: string;
};

export type ICreateShipmentSliceValues = {
  sender: CreateShipmentAddress;
  receiver: CreateShipmentAddress;
  details: CreateShipmentDetails;
  items: IShipmentPackageGrouped[];
  contents: CreateShipmentContents[];
};

export type PickUpObjectType = {
  shouldPickUp: boolean;
  pickupDate: string;
  pickupFromTime: string;
  pickupToTime: string;
  pickupInstructions: string;
};
export type FoundDropOffLocationsDistance = {
  address: FoundDropOffLocations;
  distance: string;
  carrierDetailList?: CarrierDetailList[];
  locationDetail?: LocationDetail;
  geoLocation?: GeoLocation;
  upsPhone?: string;
  upsStore?: string;
};
export type GeoLocation = {
  latitude: string;
  longitude: string;
}
export type FoundDropOffLocations = {
  city: string;
  countryCode: string;
  postalCode: string;
  residential: boolean;
  stateOrProvinceCode: string;
  displayName: string;
};
export type DropoffObjectType = {
  shouldDropOff: boolean;
  dropOffLocations: FoundDropOffLocationsDistance[];
  selectedDropOffLocation: FoundDropOffLocations;
};

export type InsuranceType = {
  useInsurance: boolean;
  insuranceAmount: number;
  insuranceAmountCurrency: string;
};
export type SetDropOffPayload = {
  shouldDropOff: boolean;
  sender: CreateAddress;
  salesAccountUUID: ISalesAccount["uuid"] | null;
};
export type ICreateShipmentSlice = {
  insurance: InsuranceType;
  isDisclaimerAccepted: boolean;
  shippingToResidence: boolean;
  pickUpObject: PickUpObjectType;
  dropOffObject: DropoffObjectType;
  documentOption: { label: string; value: number };
  editingShipmentID: IShipment["id"] | null;
  editingShipmentUUID: IShipment["uuid"] | null;
  /**
   * If quoting as an admin, the sales account UUID needs to be specified.
   * @default false
   */
  salesAccountUUID: ISalesAccount["uuid"] | null;
  files: { fileType: string; label: string; file: File | null }[];
  editingShipmentJobNumber: IShipment["jobNumber"] | null;
  printLater: boolean;
  isConvertingFromQuote: boolean;
  convertingFromQuoteStatus: "IDLE" | "LOADING" | "READY";
  values: ICreateShipmentSliceValues;
  type: ShipmentTypes;
  rates: IQuotedRate[];
  selectedRate: IQuotedRate | null;
  ratesError: string;
  ratesLoadingStatus: ApiLoadingStatus;
  submittingStatus: ApiLoadingStatus;
  submittingErrorMessage: string;
  submitCount: number;
  errors: CreateShipmentErrors;
  touched: {
    sender: CreateShipmentAddressTouched;
    receiver: CreateShipmentAddressTouched;
    items: { [key in keyof IShipmentPackageGrouped]: boolean }[];
    contents: { [key in keyof CreateShipmentContents]: boolean }[];
    details: { [key in keyof CreateShipmentDetails]: boolean };
  };
  currentQuoteReference: string;
  dangerousGoodsOption: { label: string; value: number };
  dangerousGoodsCategories: { label: string; value: number }[];
  duplicateOrEdit: string;
};

export type CreateShipmentAddressErrors = {
  [key in keyof CreateShipmentAddress]: string;
};

export type CreateShipmentAddressTouched = {
  [key in keyof Omit<
    CreateShipmentAddress,
    "id" | "saveToAddressBook" | "type"
  >]: boolean;
};

export type CreateShipmentItemErrors = {
  [key in keyof IShipmentPackageGrouped]: PackageErrorCodes | null;
};

export type CreateShipmentContentsErrors = {
  [key in keyof CreateShipmentContents]: string;
};

export type CreateShipmentDetailsErrors = {
  [key in keyof CreateShipmentDetails]: string;
};

export type CreateShipmentErrors = {
  sender: CreateShipmentAddressErrors;
  receiver: CreateShipmentAddressErrors;
  items: CreateShipmentItemErrors[];
  contents: CreateShipmentContentsErrors[];
  details: CreateShipmentDetailsErrors;
  itemAndPackage?: { itemAndPackageWeight: string }[];
};
