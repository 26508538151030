import { PrintJSON } from "components/atoms/PrintJSON";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ShipmentTypes } from "enum/shipment-types.enum";
import { CreateQuoteForm } from "./CreateQuoteForm";
import { CreateShipmentFormSteps } from "./CreateShipmentForm/CreateShipmentFormSteps";
import { CreateShipmentFormFooter } from "./CreateShipmentForm/CreateShipmentFormFooter";
import {
  CreateShipmentFormStepsConfig,
  CreateShipmentFormStepPickUp,
  CreateShipmentFormStepInsurance,
  CreateShipmentFormStepDetails,
} from "./CreateShipmentForm/CreateShipmentForm.config";
import { CreateShipmentRates } from "./common/CreateShipmentFormRates";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { Divider } from "components/atoms/Divider";
import { ApiPath } from "enum/apiPath.enum";
import {
  setPickUp,
  setDropOff,
  setPickUpDate,
  setPickUpFromTime,
  setPickUpToTime,
  setPickUpInstructions,
  setSelectedLocation,
} from "store/createShipmentSlice/index.slice";

export type ICreateShipmentForm = {
  handleFetchRates(): void;
  handleFinaliseShipment({ printLater }: { printLater: boolean }): void;
};

export const CreateShipmentForm = (props: ICreateShipmentForm) => {
  const debug = false;

  const { handleFetchRates, handleFinaliseShipment } = props;

  const createShipment = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  const { financialCompany } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const {
    selectedRate,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    values: { details, sender, receiver },
    pickUpObject,
    dropOffObject,
    //submitCount,
    //errors,
    isDisclaimerAccepted,
  } = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  let shouldAllowPickUpForm = false;

  const shouldAllowPickUpFormDSV =
    selectedRate?.supplier?.apiPath === ApiPath["Fedex (DSV)"] ||
    (selectedRate?.supplier?.apiPath === ApiPath["Fedex Domestic USA"] &&
      financialCompany === 8) ||
    (selectedRate?.supplier?.apiPath === ApiPath["UPS (Normal)"] &&
      financialCompany === 8) ||
    selectedRate?.supplier?.apiPath === ApiPath["UPS (DSV)"];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const shouldAllowPickUpFormNonDSVUPS =
    selectedRate?.supplier?.apiPath === ApiPath["UPS (Normal)"] &&
    createShipment?.values?.sender?.countryIsoCode === "GB";

  // shouldAllowPickUpForm =
  //   shouldAllowPickUpFormDSV || shouldAllowPickUpFormNonDSVUPS;

  shouldAllowPickUpForm = shouldAllowPickUpFormDSV;

  const shouldAllowInsuranceForm =
    selectedRate?.supplier?.apiPath === ApiPath["Fedex (DSV)"] ||
    selectedRate?.supplier?.apiPath === ApiPath["Fedex Domestic USA"] ||
    selectedRate?.supplier?.apiPath === ApiPath["UPS (DSV)"];

  const pickUpFormProps = {
    dropOffObject,
    pickUpObject,
    setPickUp,
    sender,
    setDropOff,
    setSelectedLocation,
    setPickUpDate,
    setPickUpFromTime,
    setPickUpToTime,
    setPickUpInstructions,
    shouldAllowPickUpForm,
    selectedRate,
  };

  const detailsStepNumber = shouldAllowInsuranceForm ? 4 : 3;

  return (
    <>
      {debug && <PrintJSON json={createShipment} />}
      {createShipment.type === ShipmentTypes.QUOTE ? (
        <CreateQuoteForm />
      ) : (
        <>
          <CreateShipmentFormSteps steps={CreateShipmentFormStepsConfig()} />
          {createShipment.ratesLoadingStatus !== ApiLoadingStatus.IDLE && (
            <>
              <Divider />
              <CreateShipmentRates />
            </>
          )}
          {shouldAllowInsuranceForm && (
            <>
              <Divider />
              <CreateShipmentFormSteps
                steps={CreateShipmentFormStepInsurance()}
              />
            </>
          )}
          {selectedRate && (
            <>
              <Divider />
              <CreateShipmentFormSteps
                steps={CreateShipmentFormStepDetails(detailsStepNumber)}
              />
            </>
          )}
          {shouldAllowPickUpForm && (
            <>
              <Divider />
              <CreateShipmentFormSteps
                steps={CreateShipmentFormStepPickUp(
                  pickUpFormProps,
                  financialCompany
                )}
              />
            </>
          )}

          <CreateShipmentFormFooter
            selectedRate={createShipment.selectedRate}
            rates={createShipment.rates}
            ratesLoadingStatus={createShipment.ratesLoadingStatus}
            submittingStatus={createShipment.submittingStatus}
            handleFinaliseShipment={handleFinaliseShipment}
            handleFetchRates={handleFetchRates}
            isDisclaimerAccepted={isDisclaimerAccepted}
          />
        </>
      )}
    </>
  );
};
