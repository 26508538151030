import { CarrierTypes } from "enum/carrier-types.enum";
import { ITariff } from "models/tariff/tariff.model";

export const printTariffName = (tariff: ITariff, type: CarrierTypes) => {
  if (type === CarrierTypes.INTERNATIONAL) {
    if (tariff?.directionType === 4) {
      return " - Domestic";
    } else if (tariff?.directionType === 1) {
      return " - Export";
    } else {
      return " - Import";
    }
  }

  if (tariff.alias) {
    ` - ${tariff.alias}`;
  } else {
    return "";
  }
};
