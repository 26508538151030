import {
  DomesticCarriersDatabaseIDs,
  IntlCarriersDatabaseIDs,
} from "enum/carrier-mappings.enum";
import { ShipmentDirections } from "enum/shipment-directions.enum";

export const TRACKING_CODE_PARAM = ":trackingCode";
export const YODEL_TRACKING_URL = `https://www.yodel.co.uk/tracking/${TRACKING_CODE_PARAM}`;
export const EVRI_DOMESTIC_TRACKING_URL = `https://www.evri.com/track/parcel/${TRACKING_CODE_PARAM}`;
export const EVRI_INTERNATIONAL_TRACKING_URL = `https://international.evri.com/tracking/${TRACKING_CODE_PARAM}`;
export const UPS_TRACKING_URL = `https://www.ups.com/track?track=yes&trackNums=${TRACKING_CODE_PARAM}&requester=ST&loc=en_US/trackdetails`;
export const AMAZON_TRACKING_URL = `https://track.amazon.co.uk/tracking/${TRACKING_CODE_PARAM}`;
export const FEDEX_TRACKING_URL = `https://www.fedex.com/fedextrack/?trknbr=${TRACKING_CODE_PARAM}`;
export const ROYAL_MAIL_TRACKING_URL = `https://www.royalmail.com/track-your-item?trackNumber=${TRACKING_CODE_PARAM}`;
export const STARLINKS_TRACKING_URL = `https://www.starlinks.app/track/${TRACKING_CODE_PARAM}`;
// Can't seem to find a url that will redirect to a specific tracking number
export const DPD_TRACKING_URL = `https://track.dpd.co.uk/`;

/**
 * Returns the full tracking URL for a parcel. Returns nothing if the carrier isn't supported.
 * @param trackingCode the tracking code
 * @param carrierId the carrier database ID
 * @param shipmentDirection some carriers, like Evri, have different portals for international vs domestic shipments
 */

export const getTrackingUrl = (
  trackingCode: string | null,
  carrierId: string | null,
  shipmentDirection: ShipmentDirections
): string => {
  // Joe/Milad: todo, need to handle domestic and international carriers properly. Domestic and Intl carriers can have same IDs. Need to pass in whether it's domestic or international.
  switch (carrierId) {
    case DomesticCarriersDatabaseIDs.EVRI:
      switch (shipmentDirection) {
        case ShipmentDirections.DOMESTIC:
          return EVRI_DOMESTIC_TRACKING_URL.replace(
            TRACKING_CODE_PARAM,
            trackingCode
          );

        default:
          return EVRI_INTERNATIONAL_TRACKING_URL.replace(
            TRACKING_CODE_PARAM,
            trackingCode
          );
      }

    case DomesticCarriersDatabaseIDs.YODEL:
      return YODEL_TRACKING_URL.replace(TRACKING_CODE_PARAM, trackingCode);

    case DomesticCarriersDatabaseIDs.UPS:
      return UPS_TRACKING_URL.replace(TRACKING_CODE_PARAM, trackingCode);

    case DomesticCarriersDatabaseIDs.AMAZON:
      return AMAZON_TRACKING_URL.replaceAll(TRACKING_CODE_PARAM, trackingCode);

    case DomesticCarriersDatabaseIDs.FEDEX:
      return FEDEX_TRACKING_URL.replaceAll(TRACKING_CODE_PARAM, trackingCode);

    case IntlCarriersDatabaseIDs.FedexUSA.toString():
      return FEDEX_TRACKING_URL.replaceAll(TRACKING_CODE_PARAM, trackingCode);

    case IntlCarriersDatabaseIDs.DPD.toString():
      return DPD_TRACKING_URL;

    case IntlCarriersDatabaseIDs.RoyalMail.toString(): {
      return ROYAL_MAIL_TRACKING_URL.replaceAll(
        TRACKING_CODE_PARAM,
        trackingCode
      );
    }

    case IntlCarriersDatabaseIDs.STARLINKS.toString():
      return STARLINKS_TRACKING_URL.replaceAll(
        TRACKING_CODE_PARAM,
        trackingCode
      );

    default:
      return "";
  }
};
