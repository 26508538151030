import { Select } from "components/atoms/Select";
import { generateSelectOptions } from "components/atoms/Select/UseSelect";
import { FormControl } from "components/molecules/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { uniqBy } from "lodash";
import {
  setCarrierId,
  setShippingWhitelistId,
} from "store/createOrEditRuleSlice/index.slice";
import { Panel } from "components/atoms/Panel";
import { printTariffName } from "utils/printTariffName";
import { printSupplierName } from "utils/printSupplierName";
import { PanelHeader } from "components/atoms/Panel/PanelHeader";
import { PanelBody } from "components/atoms/Panel/PanelBody";
import { PanelTitle } from "components/atoms/Panel/PanelTitle";

export const RuleFormActions = () => {
  const dispatch = useDispatch();

  const { items } = useSelector((state: RootState) => {
    return state.virtualWarehousesSlice;
  });

  const {
    values: { virtualWarehouseId },
  } = useSelector((state: RootState) => {
    return state.createOrEditRuleSlice;
  });

  const shippingWhitelists =
    items.find((warehouse) => warehouse.id === virtualWarehouseId)
      ?.whitelists || [];

  const carriers = uniqBy(
    shippingWhitelists.map((whitelist) => whitelist.carrier),
    "id"
  );

  const {
    values: { shippingWhitelistId, carrierId },
    errors,
  } = useSelector((state: RootState) => {
    return state.createOrEditRuleSlice;
  });

  const carrierSelectOptions = generateSelectOptions({
    data: carriers,
    labelKey: "name",
    valueKey: "id",
  });

  const whitelistSelectOptions = generateSelectOptions({
    data: carrierId
      ? shippingWhitelists.filter((whitelist) => {
          return whitelist.carrierId == carrierId;
        })
      : [],
    labelKey: "carrierId",
    valueKey: "id",
  });

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>Do</PanelTitle>
      </PanelHeader>
      <PanelBody>
        <FormControl label="Assign to carrier">
          <Select
            isNullable
            onChange={(value) => dispatch(setCarrierId(value?.value as string))}
            isSearchable={false}
            tabIndex={5}
            isInvalid={errors.carrierId ? true : false}
            value={
              carrierSelectOptions.find((carrier) => {
                return carrier.id == carrierId;
              }) || null
            }
            placeholder="Select"
            options={carrierSelectOptions}
          />
        </FormControl>

        {carrierId && (
          <div className="mt-3">
            <FormControl label="Assign to supplier and service">
              <Select
                isNullable
                onChange={(value) =>
                  dispatch(setShippingWhitelistId(value?.value as string))
                }
                isSearchable={false}
                tabIndex={6}
                isInvalid={errors.shippingWhitelistId ? true : false}
                value={
                  whitelistSelectOptions.find((whitelist) => {
                    return whitelist.id == shippingWhitelistId;
                  }) || null
                }
                optionTemplate={({
                  supplier,
                  service,
                  tariff,
                  type,
                  carrierId,
                }) => {
                  return (
                    <div>
                      {service && `${service.name}`}
                      {supplier &&
                        `${printSupplierName(supplier, carrierId, type)}`}
                      {tariff && `${printTariffName(tariff, type)}`}
                    </div>
                  );
                }}
                placeholder="Select"
                options={whitelistSelectOptions}
              />
            </FormControl>
          </div>
        )}
      </PanelBody>
    </Panel>
  );
};
