import React from "react";

interface GeoLocation {
  latitude: number;
  longitude: number;
}

const handleRedirect = (geoLocation: GeoLocation) => () => {
  const { latitude, longitude } = geoLocation;
  const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
  window.open(url, "_blank"); // Opens in a new tab
};

// Usage within the component
const GoogleMapRedirect = ({ geoLocation }: { geoLocation: GeoLocation }) => (
  <>
    <button
      onClick={handleRedirect(geoLocation)}
      className="text-blue-500 underline cursor-pointer p-0 bg-transparent border-none"
    >
      View on Google Maps
    </button>
  </>
);

export default GoogleMapRedirect;
